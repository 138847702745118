<template>
  <!--  <v-container class="line-item-detail-new"> -->
  <v-row>
    <v-col md="12">
      <table class="line-item-multiple-table" width="100%">
        <thead>
          <tr class="custom-border">
            <th class="text-left border-right-new" width="10%">DO Number</th>
            <th class="text-left border-right-new" width="10%">Date</th>
            <th class="text-left border-right-new" width="15%">Drivers</th>
            <th class="text-left border-right-new" width="15%">Item</th>
            <th class="text-left border-right-new" width="5%">
              To Be Delivered
            </th>
            <th class="text-left border-right-new" width="5%">Delivered</th>
            <!-- <th class="text-left">
                            Project Price
                        </th> -->
            <th class="text-left border-right-new" width="5%">Rejected</th>
            <th class="text-left border-right-new" width="5%">Balance</th>
            <th class="text-left border-right-new" width="10%">Signature</th>
            <th class="text-left border-right-new" width="10%">Reason</th>
            <th class="text-left border-right-new" width="10%">Remark</th>
          </tr>
        </thead>
        <tbody>
          <template v-if="receivedLineItema && receivedLineItema.length > 0">
            <template v-for="(line_item, index) in receivedLineItema">
              <tr
                :key="index"
                class="line-item-listing-row"
                :class="{ 'custom-border-top': index > 0 }"
              >
                <td valign="top" class="text-left border-right-new" width="10%">
                  <v-chip
                    small
                    label
                    outlined
                    text-color=""
                    class="mt-2 custom-status font-weight-600 custom-grey-border text-uppercase"
                    color="orange"
                  >
                    {{ parentDetail.barcode }}-
                    {{ getDetailBarcode(line_item.sub_delivery_barcode) }}
                  </v-chip>
                  <!--  <div>
                                    <template v-if="line_item.signature_type == 'delivery-order' || line_item.signature_type == 'partial'">
                                        <v-chip small label class="mt-2 custom-status font-weight-600 custom-grey-border"
                                            text-color="white" color="orange darken-4"> Partially Delivered
                                        </v-chip>    
                                    </template>
                                    <template v-if="line_item.signature_type == 'failed'">
                                        <v-chip small label class="mt-2 custom-status font-weight-600 custom-grey-border"
                                            text-color="white" color="red"> Failed
                                        </v-chip>
                                    </template>
                                    <template v-if="line_item.signature_type == 'full'">                                  
                                        <v-chip small label class="mt-2 custom-status font-weight-600 custom-grey-border"
                                            text-color="white" color="green darken-4"> Fully Delivered
                                        </v-chip>
                                    </template>
                                </div> -->
                </td>
                <td valign="top" class="text-left border-right-new" width="10%">
                  <v-chip
                    small
                    label
                    class="mt-2 custom-status font-weight-600 custom-grey-border text-uppercase"
                    text-color=""
                    color="green"
                    outlined
                  >
                    {{ formatDate(line_item.added_at) }}
                  </v-chip>
                </td>
                <td valign="top" class="text-left border-right-new" width="15%">
                  <!--  {{ line_item.drivers }} -->
                  <template v-for="(row, CIndex) in line_item['drivers']">
                    <v-chip
                      class="mb-1 mr-1"
                      :key="CIndex"
                      v-if="row && row.engineerData"
                      ><v-avatar left small>
                        <img
                          v-if="
                            row &&
                            row.engineerData.profile_logo &&
                            row.engineerData.profile_logo.file
                          "
                          :src="row.engineerData.profile_logo.file.url"
                        />
                        <img
                          v-else
                          src="https://ams-dev.businessthrust.com//media/misc/no_photo_found.png"
                        />
                      </v-avatar>
                      <span>{{ row.engineerData.display_name }}</span>
                    </v-chip>
                  </template>
                  <!-- <v-chip class="mb-1 mr-1"
                                    ><v-avatar left small>
                                        <img v-if="line_item && line_item.engineer" />
                                        <img
                                        v-else
                                        src="https://ams-dev.businessthrust.com//media/misc/no_photo_found.png"
                                        />
                                    </v-avatar>
                                    <span>Joe Deo</span>
                                </v-chip>
                                <v-chip class="mb-1 mr-1"
                                    ><v-avatar left small>
                                        <img v-if="line_item && line_item.engineer" />
                                        <img
                                        v-else
                                        src="https://ams-dev.businessthrust.com//media/misc/no_photo_found.png"
                                        />
                                    </v-avatar>
                                    <span>Testign</span>
                                </v-chip> -->
                </td>
                <!--  <pre>{{ line_item }}</pre> -->
                <td valign="top" class="text-left border-right-new" width="15%">
                  <v-layout>
                    <v-flex md12 class="d-flex">
                      <div class="mr-2">
                        <v-avatar class="avatar-custom">
                          <v-img
                            v-if="line_item && line_item.product"
                            class="customer-image"
                            width="70"
                            height="70"
                            contain
                            :lazy-src="$defaultImage"
                            :src="getProductImage(line_item.product)"
                          >
                          </v-img>
                        </v-avatar>
                      </div>
                      <div class="w-100">
                        <p class="m-0 p-2 custom-grey-border">
                          <template
                            v-if="
                              line_item &&
                              line_item.product &&
                              line_item.product.name
                            "
                            >{{ line_item.product.name }}</template
                          >
                          <template v-else
                            ><em class="text--secondary">No name</em></template
                          >
                        </p>
                        <v-chip
                          small
                          label
                          class="ml-1 mt-2 custom-status font-weight-600 custom-grey-border text-uppercase"
                          text-color="white"
                          color="cyan"
                        >
                          <template
                            v-if="
                              line_item &&
                              line_item.product &&
                              line_item.product.barcode
                            "
                            >{{ line_item.product.barcode }}</template
                          >
                        </v-chip>
                      </div>
                    </v-flex>
                  </v-layout>
                </td>
                <td valign="top" class="text-left border-right-new" width="5%">
                  <v-chip
                    label
                    text-color=""
                    outlined
                    class="chip-custom-font"
                    color="blue"
                  >
                    {{ getToBe(line_item) }}
                  </v-chip>
                </td>
                <td valign="top" class="text-left border-right-new" width="5%">
                  <v-chip
                    label
                    text-color=""
                    outlined
                    class="chip-custom-font"
                    color="green"
                  >
                    {{ line_item.deliverd_qty }}
                  </v-chip>
                </td>
                <td valign="top" class="text-left border-right-new" width="5%">
                  <v-chip
                    label
                    text-color=""
                    outlined
                    class="chip-custom-font"
                    color="red"
                  >
                    {{ line_item.rejected_qty }}
                  </v-chip>
                </td>
                <td valign="top" class="text-left border-right-new" width="5%">
                  <v-chip
                    label
                    text-color=""
                    outlined
                    class="chip-custom-font"
                    color="orange"
                  >
                    {{ line_item.balance_to_be_deliverd_qty }}
                  </v-chip>
                </td>
                <td
                  valign="top"
                  class="text-left border-right-new text-capitalize"
                  width="10%"
                >
                  <v-img
                    contain
                    class="customer-image"
                    width="240"
                    height="55"
                    v-if="line_item.signature"
                    :lazy-src="$defaultImage"
                    :src="line_item.signature"
                    v-on:click="download(line_item.signature)"
                  ></v-img>
                  <v-img
                    v-else
                    class="customer-image"
                    width="70"
                    height="70"
                    contain
                    :lazy-src="$defaultImage"
                    :src="$defaultImage"
                  ></v-img>
                </td>
                <td
                  valign="top"
                  class="text-left border-right-new text-capitalize"
                  width="10%"
                >
                  <template v-if="line_item && line_item.cancel_reason_type">
                    {{
                      line_item.cancel_reason_type.replace("-", " ")
                    }}</template
                  >
                  <template
                    v-else-if="line_item && line_item.failed_reason_type"
                  >
                    {{ line_item.failed_reason_type.replace("-", " ") }}
                  </template>
                  <em class="text-muted" v-else> no reason </em>
                </td>
                <td valign="top" class="text-left border-right-new" width="10%">
                  <template v-if="line_item && line_item.cancel_reason">
                    {{ line_item.cancel_reason }}</template
                  >
                  <template v-else-if="line_item && line_item.failed_reason">
                    {{ line_item.failed_reason }}
                  </template>
                  <em class="text-muted" v-else> no remark </em>
                </td>
              </tr>
            </template>
          </template>
          <template v-else>
            <tr>
              <td :colspan="10" class="py-4">
                <p
                  class="m-0 row-not-found text-center font-weight-500 font-size-16"
                >
                  <img
                    :src="$assetURL('media/error/empty.png')"
                    class="row-not-found-image"
                  />
                  Uhh... There are no received delivery at the moment.
                </p>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </v-col>
  </v-row>
</template>
<script>
import {
  GET,
  /* QUERY,
    PATCH,
    POST,
    PUT, */
} from "@/core/services/store/request.module";
import CommonMixin from "@/core/plugins/common-mixin";
import { DeliveryEventBus } from "@/core/lib/delivery.lib.js";

export default {
  mixins: [CommonMixin],
  props: {
    parentDetail: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      receivedLineItema: [],
      pageLoading: false,
    };
  },
  methods: {
    getToBe(row) {
      let total = row.deliverd_qty + row.rejected_qty;
      return total;
    },
    download(row) {
      window.open(row, "_blank");
    },
    getDetailBarcode(chlidBarcode) {
      if (chlidBarcode) {
        let lastnumber = chlidBarcode.slice(-2);
        return lastnumber;
      } else {
        return false;
      }
    },
    getReceivedLineItem() {
      this.pageLoading = true;
      this.$store
        .dispatch(GET, {
          url: "recevied-line-item/" + this.parentDetail.id,
        })
        .then(({ data }) => {
          this.receivedLineItema = data;
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.pageLoading = false;
        });
    },
    getProductImage(product_logo) {
      if (
        product_logo &&
        product_logo.product_image &&
        product_logo.product_image.file &&
        product_logo.product_image.file.url
      ) {
        return product_logo.product_image.file.url;
      }
      return null;
    },
  },
  mounted() {
    if (this.parentDetail.id > 0) {
      this.getReceivedLineItem();
    }
    DeliveryEventBus.$on("update:delivery", () => {
      this.getReceivedLineItem();
    });
  },
  components: {
    /*  QuantityInput */
  },
};
</script>
